import { RichText } from 'components/RichText';
import { useMemo } from 'react';
import styles from './PercentageBar.module.css';

interface Properties {
  description?: string;
  highPercentageIsGreen?: boolean;
  small?: boolean;
  value: number;
}

export const PercentageBar = ({ value, small = false, highPercentageIsGreen = false, description }: Properties) => {
  const indicatorClassName = useMemo(() => {
    const className = [styles.percentageIndicator];

    if (!value) {
      return className.join(' ');
    }

    if (value > 79) {
      className.push(highPercentageIsGreen ? styles.green : styles.red);
    } else if (value > 59) {
      className.push(styles.orange);
    } else if (value) {
      className.push(highPercentageIsGreen ? styles.red : styles.green);
    }

    return className.join(' ');
  }, [value, highPercentageIsGreen]);

  const containerClassName = useMemo(() => {
    const className = [styles.indicator];

    if (!small) {
      return className.join(' ');
    }

    if (small) {
      className.push(styles.small);
    }

    return className.join(' ');
  }, [small]);

  return (
    <div className={styles.container}>
      <RichText>{!!description && <p>{description}</p>}</RichText>
      <span className={styles.value} style={{ left: `${value ? value - 2 : 0}%` }}>
        {/*-2%, otherwise the percentage will overflow off the card*/}
        {value}%
      </span>
      <div className={containerClassName}>
        <div className={indicatorClassName} style={{ width: `${value}%` }} />
      </div>
    </div>
  );
};
