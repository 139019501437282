import { ReactNode } from 'react';
import styles from './DataList.module.css';

export interface DataListProperties {
  data: { label?: string; children?: ReactNode }[];
}

export const DataList = ({ data }: DataListProperties) => (
  <div className={styles.container}>
    {data.map(({ label, children }) => (
      <div className={styles.item} key={label}>
        {!!label && <div className={styles.label}>{label}</div>}
        <div className={styles.children}>{children || '-'}</div>
      </div>
    ))}
  </div>
);
