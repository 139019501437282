import { DataListCard } from 'components/DataListCard';
import { Grid } from 'components/Grid';
import { OccupationTile } from 'components/OccupationTile';
import { PercentageBar } from 'components/PercentageBar';
import { RichText } from 'components/RichText';
import { VerticalMargin } from 'components/VerticalMargin';
import { Statistics } from 'helpers/types';
import { useMemo } from 'react';
import styles from './DashboardStatistics.module.css';

interface Properties {
  data: Statistics;
  totalCostsAllBuildings?: number;
  totalIncomeAllBuildings?: number;
}

export const DashboardStatistics = ({ data, totalCostsAllBuildings, totalIncomeAllBuildings }: Properties) => {
  const buildingData = useMemo(() => {
    const totalProfitAllBuildings = (totalIncomeAllBuildings ?? 0) - (totalCostsAllBuildings ?? 0);
    return [
      {
        label: 'Totale inkomsten alle woningen',
        children: !!totalIncomeAllBuildings && `€ ${totalIncomeAllBuildings},-`,
      },
      {
        label: 'Totale kosten alle woningen',
        children: !!totalCostsAllBuildings && `€ ${totalCostsAllBuildings},-`,
      },
      {
        label: 'Totaal resultaat alle woningen',
        children: totalIncomeAllBuildings && totalCostsAllBuildings && (
          <span className={totalProfitAllBuildings < 0 ? styles.negativeBuildingProfit : undefined}>
            € {totalProfitAllBuildings},-
          </span>
        ),
      },
    ].filter(({ children }) => !!children);
  }, [totalCostsAllBuildings, totalIncomeAllBuildings]);

  const candidateData = useMemo(
    () =>
      [
        { label: 'Aantal medewerkers met een bed', children: data.candidates.inRooms },
        { label: 'Aantal medewerkers in een auto', children: data.candidates.inCars },
      ].filter(({ children }) => !!children),
    [data.candidates.inCars, data.candidates.inRooms]
  );

  return (
    <div>
      <div className={styles.title}>
        <RichText>
          <h2>Totale bezetting</h2>
          <p>De aantallen zijn gebaseerd op de totale bezetting plus reserveringen.</p>
        </RichText>
      </div>
      <VerticalMargin>
        <Grid>
          <OccupationTile
            title="Bezetting bedden"
            total={data.beds.total}
            amountIncludingReservations={data.beds.reserved + data.beds.used}
            amountExcludingReservations={data.beds.used}
            reservedAmount={data.beds.reserved}
            icon="bed">
            <PercentageBar description="Bezetting inclusief reserveringen" value={data.beds.percentage} />
          </OccupationTile>
          <OccupationTile
            title="Bezetting in auto's"
            total={data.carSeats.total}
            amountIncludingReservations={data.carSeats.reserved + data.carSeats.used}
            amountExcludingReservations={data.carSeats.used}
            isCar
            icon="car">
            <PercentageBar description="Bezetting inclusief reserveringen" value={data.carSeats.percentage} />
          </OccupationTile>
        </Grid>
      </VerticalMargin>
      <div className={styles.title}>
        <RichText>
          <h2>Algemene statistieken</h2>
        </RichText>
      </div>
      <Grid>
        {totalCostsAllBuildings || totalIncomeAllBuildings ? (
          <DataListCard title="Woningen" data={buildingData} />
        ) : undefined}
        <DataListCard title="Medewerkers" data={candidateData} />
      </Grid>
    </div>
  );
};
