import { Button, ButtonProperties } from 'components/Button';
import { Loader } from 'components/Loader';

import styles from './PageHeader.module.css';

interface Properties {
  buttons?: ButtonProperties[];
  color?: 'light' | 'green' | 'red';
  showLoader?: boolean;
  title: string;
}

export const PageHeader = ({ buttons, color, showLoader, title }: Properties) => (
  <div className={styles.container}>
    <h1 className={styles.title}>{title}</h1>
    <div className={styles.buttonContainer}>
      {!!showLoader && <Loader />}
      {buttons?.map((button, index) => <Button key={index} color={color} {...button} />)}
    </div>
  </div>
);
