import { Loader } from 'components/Loader';
import { Snapshot } from 'helpers/types';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';

interface Properties {
  snapshots?: Snapshot[];
  isLoading?: boolean;
}

export const OccupationChart = ({ snapshots, isLoading }: Properties) => {
  const [screenWidth, setScreenWidth] = useState<number>(1000);

  const formattedData = useMemo(() => {
    if (!snapshots) return;

    return snapshots.map(({ bedUsed, carUsagePercentage, createdAt, bedTotal }) => {
      const formattedDateToDutchNotation = createdAt
        ? new Date(createdAt).toLocaleDateString('nl-NL', { timeZone: 'europe/amsterdam' })
        : undefined;
      return {
        name: formattedDateToDutchNotation,
        bedUsed,
        carUsagePercentage,
        bedTotal,
      };
    });
  }, [snapshots]);

  useLayoutEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <BarChart width={screenWidth * 0.8} height={250} data={formattedData}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="bedUsed" name="Aantal gebruikte bedden" fill="#04ac01" />
      <Bar dataKey="bedTotal" name="Totaal aantal bedden" fill="#0021cc" />
      <Bar dataKey="carUsagePercentage" name="Bezettingspercentage auto's" fill="#be5c23" />
    </BarChart>
  );
};
