import { Button, ButtonProperties } from 'components/Button';
import { DataList, DataListProperties } from 'components/DataList';
import { RichText } from 'components/RichText';
import styles from './DataListCard.module.css';

interface Properties {
  data?: DataListProperties['data'];
  headerButton?: ButtonProperties;
  title?: string;
}

export const DataListCard = ({ data, headerButton, title }: Properties) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <RichText>
        <h2>{title}</h2>
      </RichText>

      {!!headerButton && <Button {...headerButton} />}
    </div>
    {!!data && <DataList data={data} />}
  </div>
);
