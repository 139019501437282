import { RichText } from 'components/RichText';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './DateRangePicker.module.css';

interface Properties {
  onChangeStartDate: (date: Date) => void;
  onChangeEndDate: (date: Date) => void;
  startDate: Date | null;
  endDate: Date | null;
}

export const DateRangePicker = ({ onChangeStartDate, onChangeEndDate, startDate, endDate }: Properties) => (
  <div className={styles.container}>
    <div>
      <RichText>
        <h3>Startdatum</h3>
      </RichText>
      <DatePicker
        selected={startDate}
        onChange={onChangeStartDate}
        selectsStart
        startDate={startDate}
        endDate={endDate}
      />
    </div>
    <div>
      <RichText>
        <h3>Einddatum</h3>
      </RichText>
      <DatePicker
        selected={endDate}
        onChange={onChangeEndDate}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
      />
    </div>
  </div>
);
